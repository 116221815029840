import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"our-services"} />
      <Helmet>
        <title>
          Our Services | Explore Our Services at Seaside Serenity Yachts
        </title>
        <meta
          name={"description"}
          content={"Sail Beyond Your Expectations with Every Voyage"}
        />
        <meta
          property={"og:title"}
          content={
            "Our Services | Explore Our Services at Seaside Serenity Yachts"
          }
        />
        <meta
          property={"og:description"}
          content={"Sail Beyond Your Expectations with Every Voyage"}
        />
        <meta
          property={"og:image"}
          content={"https://brevixstone.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://brevixstone.com/img/icon-3453667.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://brevixstone.com/img/icon-3453667.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://brevixstone.com/img/icon-3453667.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://brevixstone.com/img/icon-3453667.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://brevixstone.com/img/icon-3453667.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://brevixstone.com/img/icon-3453667.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="90px 0 100px 0" quarkly-title="List-3">
        <Box display="flex" flex-direction="row" padding="0px 0px 50px 0px">
          <Box
            display="flex"
            width="40%"
            flex-direction="column"
            justify-content="center"
            align-items="flex-start"
            lg-align-items="center"
            lg-margin="0px 0px 20px 0px"
            sm-padding="0px 0px 0px 0px"
            padding="16px 16px 16px 0px"
            lg-width="100%"
            md-margin="0px 0px 20px 0px"
            sm-margin="0px 0px 30px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--dark"
              font="--headline1"
              lg-text-align="center"
              sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
              padding="0px 16px 0px 0px"
            >
              Our Services Breakdown
            </Text>
          </Box>
          <Box
            display="flex"
            width="60%"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            lg-align-items="center"
            lg-margin="0px 0px 0px 0px"
            sm-padding="0px 0px 0px 0px"
            padding="28px 0px 16px 16px"
            lg-width="100%"
            md-margin="0px 0px 20px 0px"
            sm-margin="0px 0px 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL1"
              font="--lead"
              lg-text-align="center"
              display="flex"
              align-items="flex-start"
            >
              At Seaside Serenity Yachts, we’re dedicated to providing more than
              just a boat ride. Our comprehensive array of services is designed
              to ensure a seamless and enriching sea journey. From customized
              itineraries to exclusive onboard amenities, we cater to every
              aspect of your maritime adventure. Discover the variety of
              services we offer, each tailored to enhance your experience on the
              waters.
            </Text>
          </Box>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="0 34px"
          md-grid-template-columns="1fr"
          md-grid-gap="36px 0"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://brevixstone.com/img/4.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Customized Charters
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Tailored Itineraries: Each trip is carefully planned according to
              your preferences, ensuring a personalized route and stops.
              <br />
              <br />
              Event Celebrations: Perfect for birthdays, anniversaries, or
              special occasions, we make your milestones memorable with
              customized themes and decorations.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://brevixstone.com/img/5.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Guided Tours
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Marine Education: Learn about marine life and local ecology from
              knowledgeable guides.
              <br />
              <br />
              Historical Routes: Explore historical coastlines and learn about
              the area's past, guided by expert historians.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://brevixstone.com/img/6.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Onboard Amenities
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Gourmet Dining: Enjoy meals prepared onboard by skilled chefs,
              using fresh, local ingredients.
              <br />
              <br />
              Luxury Comforts: High-quality linens, sun decks, and comfortable
              lounging areas to enhance relaxation.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="List-3">
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="0 34px"
          md-grid-template-columns="1fr"
          md-grid-gap="36px 0"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://brevixstone.com/img/7.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Multi-Day Excursions
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Overnight Accommodations: Yachts equipped with comfortable
              sleeping quarters for longer stays.
              <br />
              <br />
              Extended Itineraries: Explore further and deeper with plans that
              span several days.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://brevixstone.com/img/8.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Advanced Booking Privileges
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Priority Selection: Early access to booking dates and yacht
              selections for returning guests.
              <br />
              <br />
              Flexible Scheduling: Change or customize your booking dates with
              ease, accommodating unexpected plans.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 28px/1.2 --fontFamily-sans"
              height="400px"
              display="flex"
              align-items="flex-end"
              padding="0px 15px 35px 15px"
              background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://brevixstone.com/img/9.jpg) 50% 0% /cover repeat scroll padding-box"
              color="--light"
              lg-height="300px"
              md-height="400px"
            >
              Safety and Training
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
              color="#6a7179"
            >
              Crew Training: All crew members are extensively trained for safety
              and hospitality.
              <br />
              <br />
              Safety Equipment: State-of-the-art safety gear and navigational
              technology on all yachts.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
